import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import review1 from '../images/review1.jpg';
import review2 from '../images/review2.jpg';
import review3 from '../images/review3.jpg';
import review4 from '../images/review4.jpg';
import review5 from '../images/review5.jpg';
import review6 from '../images/review6.jpg';
import review7 from '../images/review7.jpg';
import review8 from '../images/review8.jpg';
import review9 from '../images/review9.jpg';
import styles from './Reviews.module.css';

const reviews = [
  {
    image: review1,
    author: "@Ivan_Nikulin",
    text: `Владиславу признателен за вводный экспресс-курс в рынок инвестиционной недвижимости Адлер/Сочи/Красная Поляна. При принятии инвестиционного решения очень важно наличие специалиста, не только понимающего тезисы «актив должен работать» и «разумный риск», но и способного сходу показать объекты, точно подходящие под данные критерии. Они и будут формировать ваше представление о том, куда следует вкладывать деньги с прицелом на безбедное будущее.`,
  },
  {
    image: review2,
    author: "@Irina_V_Vladimirovna",
    text: `От души благодарю Вас за поддержку при переезде. За ликбез и консультации, за помощь в выборе максимально подходящего варианта. Это так ценно! Рекомендую Вас с огромным удовольствием! Я сегодня заселяюсь в квартиру, о которой мечтала! Это при том, что полторы недели назад я не знала о недвижимости в Сочи ничего.`,
  },
  {
    image: review3,
    author: "@Shenechka123",
    text: `Владислав, хочу сказать Вам огромное спасибо! Я редко встречаю людей, которые на все 100% разбираются в своем деле, и я безмерно рада, что путь от поиска до приобретения недвижимости прошла именно с Вами. На все вопросы я сразу получала развернутый и понятный ответ, вся документальная работа прошла с легкостью. Еще раз спасибо!`,
  },
  {
    image: review4,
    author: "@AlexanderZakharov",
    text: `Хочу выразить огромную благодарность Владиславу, который помог мне и моей семье перебраться в Сочи. Сейчас уже 2-й месяц наслаждаемся жизнью в Сочи и знакомимся с городом. За это время стало понятно, что у человека, не знающего город и его локации, риск приобрести «не ту» недвижимость и разочароваться в Сочи очень велик. Очень рад, что мы избежали этого, благодаря сотрудничеству с таким порядочным человеком, которого смело можно рекомендовать друзьям.`,
  },
  {
    image: review5,
    author: "Natali",
    text: `Владислав, хочу сказать огромное спасибо за помощь в подборе квартиры. Было очень приятно с вами работать. А самое главное, мне понравилось, что на любой вопрос я получала грамотный, развернутый ответ, без каких-либо подводных камней – все четко и по делу!`,
  },
  {
    image: review6,
    author: "Aleksey Zhokhov",
    text: `Хочу оставить положительный отзыв о Владиславе. Слава Богу, что я встретил такого порядочного и ответственного человека в моем непростом выборе недвижимости. Он мне всё рассказал, показал и рекомендовал. Сейчас я понимаю, какой труд он проделал, чтобы всем всё устраивало. Большое спасибо! С уважением, Алексей.`,
  },
  {
    image: review7,
    author: "Анна Шабанова",
    text: `Владислав, спасибо вам за отличную работу! Изначально понравилось, что всегда быстро и развернуто отвечали на все вопросы. И, конечно же, огромная благодарность за то, что предложили нам отличный проект. Сделка прошла успешно, и мы очень рады покупке.`,
  },
  {
    image: review8,
    author: "Ruzia Minkina",
    text: `Хочу поблагодарить за все с первого телефонного разговора до встречи в Сочи. Вы – очень честный и порядочный человек, который с первой минуты вызывает доверие. Вы стараетесь для клиента, как для себя, это бесценно. Встречаете, провожаете, всегда держите руку на пульсе. Побольше бы таких людей, и мир стал бы лучше! Желаю светлой и доброй жизни вам и вашим близким. Спасибо за все! С уважением, Роза.`,
  },
  {
    image: review9,
    author: "Svetlana Borodina",
    text: `Спасибо за вашу помощь в приобретении квартиры в Сочи. Ваше внимание и порядочность должны служить примером для подражания. Желаю успехов в нелегкой работе агента по продаже недвижимости. Ваш индивидуальный подход к людям сразу чувствуется. Всех благ и здоровья.`,
  },
];

const renderAuthor = (author) => {
  if (author.startsWith('@')) {
    return (
      <a href={`https://t.me/${author.slice(1)}`} target="_blank" rel="noopener noreferrer" className={styles.reviewAuthor} style={{ textDecoration: 'none', color: 'inherit' }}>
        {author}
      </a>
    );
  } else {
    return <span className={styles.reviewAuthor}>{author}</span>;
  }
};

const Reviews = () => {
  return (
    <div className={styles.reviews}>
      <h2 className={styles.reviewsTitle}>Отзывы моих клиентов</h2>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={false}
        swipeable={true}
      >
        {reviews.map((review, index) => (
          <div key={index} className={styles.reviewItem}>
            <img src={review.image} alt={`Отзыв от ${review.author}`} className={styles.reviewImage} style={{ width: '180px', height: '180px' }} />
            <div className={styles.reviewContent}>
              {renderAuthor(review.author)}<br/><br/>
              <p className={styles.reviewText}>{review.text}</p>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default Reviews;
