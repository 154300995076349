import React from 'react';
import heroImage from '../images/vlad1.png';
import tgIcon from '../images/icon-tg.svg';
import waIcon from '../images/icon-wa.svg';
import styles from './Hero.module.css';

const Hero = () => {
  return (
    <section className={styles.hero}>
      <div className={styles.heroContent}>
        <div className={styles.imageContainer}>
          <img src={heroImage} alt="Владислав Носков, эксперт по недвижимости в Сочи" className={styles.heroImage} />
        </div>
        <div className={styles.textContent}>
          <h1><span className={styles.highlight}>Проверенная</span> недвижимость для жизни и&nbsp;инвестиций</h1>
          <div className={styles.textContentBlock}>
            <p>Присматриваетесь к недвижимости в Сочи, Адлере, Сириусе или на&nbsp;Красной&nbsp;Поляне?</p>
            <p>Меня зовут Владислав Носков — я эксперт по&nbsp;недвижимости.</p>
            <p>Свяжитесь со&nbsp;мной, и&nbsp;я предложу вам самые&nbsp;лучшие&nbsp;варианты.</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
