import React from 'react';
import styles from './Footer.module.css';
import telegramIcon from '../images/icon-tg.svg';

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerImg}></div>
      <div className={styles.textContent}>
        <a href="https://t.me/VladislavEstate" target="_blank" rel="noopener noreferrer" className={styles.channelLink}>
          VladislavEstate — Ваш надежный партнер в&nbsp;мире&nbsp;недвижимости.
        </a>
      </div>
    </footer>
  );
};

export default Footer;
