import React, { useState, useEffect } from 'react';
import styles from './Header.module.css';
import logoImage from '../images/logo.png';
import tgIcon from '../images/icon-tg.svg';
import waIcon from '../images/icon-wa.svg';

const Header = () => {
  const [visible, setVisible] = useState(true);
  let lastScrollY = window.scrollY;

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setVisible(lastScrollY > currentScrollY || currentScrollY < 10);
      lastScrollY = currentScrollY;
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`${styles.header} ${visible ? styles.visible : styles.hidden}`}>
      <img src={logoImage} alt="Логотип" className={styles.logo} />
      <div className={styles.contactInfo}>
        <a href="tel:+79519361533" className={styles.phoneNumber}>+7 (951) 936-15-33</a>
        <a href="https://t.me/noskov_vladislav" target="_blank" rel="noopener noreferrer">
          <img src={tgIcon} alt="Telegram" className={styles.socialIcon} />
        </a>
        <a href="https://wa.me/79519361533" target="_blank" rel="noopener noreferrer">
          <img src={waIcon} alt="WhatsApp" className={styles.socialIcon} />
        </a>
      </div>
    </div>
  );
};

export default Header;
