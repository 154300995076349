import React from 'react';
import styles from './WhyChooseUs.module.css';
import { ReactComponent as ConsultationIcon } from '../images/icon-tg.svg';
import { ReactComponent as AnalysisIcon } from '../images/icon-tg.svg';
import { ReactComponent as ConditionsIcon } from '../images/icon-tg.svg';
import { ReactComponent as PersonalApproachIcon } from '../images/icon-tg.svg';
import { FaHandshake, FaSearch, FaChartLine, FaShieldAlt } from 'react-icons/fa';

const WhyChooseUs = () => {
  return (
    <section className={styles.whyChooseUs}>
      <h2 className={styles.title}>Преимущества сотрудничества со мной</h2>
      <div className={styles.benefits}>
        <div className={styles.benefitItem}>
          <FaHandshake className={styles.icon}/>
          <p>Моя работа для вас&nbsp;—&nbsp;бесплатна. Комиссия агента уже включена в&nbsp;стоимость.</p>
        </div>
        <div className={styles.benefitItem}>
          <FaSearch className={styles.icon}/>
          <p>Всесторонний анализ объектов: открою все плюсы&nbsp;и&nbsp;минусы.</p>
        </div>
        <div className={styles.benefitItem}>
          <FaChartLine className={styles.icon}/>
          <p>Актуальная информация о рынке недвижимости Сочи и надежное плечо в&nbsp;незнакомом&nbsp;городе.</p>
        </div>
        <div className={styles.benefitItem}>
          <FaShieldAlt className={styles.icon}/>
          <p>Полная безопасность сделки и юридическое сопровождение.</p>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
