import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Feature from './components/Feature';
import Reviews from './components/Reviews';
import WhyChooseUs from './components/WhyChooseUs';
import Footer from './components/Footer';
import phone1Image from './images/phone1.png';
import phone2Image from './images/phone2.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <main>
        <Header />
        <Hero />
        <hr />
        <Feature
          image={phone1Image}
          title="Навигатор по недвижимости"
          description="Я подготовил для вас более сотни проверенных и выгодных вариантов. Вы можете сохранить понравившиеся объекты и отправить их мне для дальнейшего обсуждения. Я помогу вам найти идеальное жилье, полностью соответствующее вашим ожиданиям."
          imageSide="left"
          buttonType="primary"
          buttonText="Открыть каталог"
          buttonUrl="https://t.me/sochi_homes_bot/map"
        />
        <Feature
          image={phone2Image}
          title="Всё, что нужно знать"
          description="Полная информация о каждом объекте, включая актуальные цены и историю их изменений. Это удобный инструмент, который поможет вам узнать все подробности о недвижимости и оставаться в курсе изменений."
          imageSide="right"
          buttonType="primary"
          buttonText="Узнать больше"
          buttonUrl="https://t.me/sochi_homes_bot/map"
        />
        <WhyChooseUs />
        <Reviews />
        <Footer />
      </main>
    </div>
  );
}

export default App;

